class PostFeed {

	constructor(block) {
		this.block = block;
		this.init();
	}

	init() {
		const feed = this.block.querySelector('[data-post-feed]');
		const resultsElem = feed.querySelector('.results');
		const resultsMeta = feed.querySelector('.results-meta');
		const scrollHint = feed.querySelector('.filters__scroll-hint');
		const filters = feed.querySelector('.filters__inner');

		// on scroll hint, scroll the filters horizontally by 100px
		if (scrollHint) {
			scrollHint.addEventListener('click', () => {
				filters.scrollBy({
					left: 200,
					behavior: 'smooth',
				});
			});
		}

		if (feed) {
			// feed.addEventListener('post-feed-pre-update', (data) => {
			// 	// scroll to top of block, but add offset of 100
			// 	const blockTop = this.block.getBoundingClientRect().top + window.scrollY;
			// 	window.scrollTo({
			// 		top: blockTop - 100,
			// 		behavior: 'smooth',
			// 	});
			// });
			feed.addEventListener('post-feed-update', (data) => {
				if (!data) {
					return;
				}

				if (!data.detail && !data.detail.results) {
					if(data.detail.data){
						resultsElem.innerHTML = data.detail.data;
					}
					return;
				}

				const results = data.detail.results;
				resultsElem.innerHTML = '';

				results.forEach(result => {
					resultsElem.innerHTML += result;
				});


				if (resultsMeta) {
					resultsMeta.dataset.found = data.detail.found_posts;

					const currentPage = resultsMeta.querySelector('.current-page');
					if (currentPage) {
						currentPage.innerHTML = data.detail.page;
					}

					const totalPages = resultsMeta.querySelector('.total-pages');
					if (totalPages) {
						totalPages.innerHTML = data.detail.pages;
					}

					const total = resultsMeta.querySelector('.total');
					if (total) {
						total.innerHTML = data.detail.found_posts;
					}
				}

				const blockTop = this.block.getBoundingClientRect().top + window.scrollY;
				window.scrollTo({
					top: blockTop - 100,
					behavior: 'smooth',
				});
			});

			feed.addEventListener('post-feed-error', (data) => {
				if(data.detail && data.detail.reason){
					resultsElem.innerHTML = data.detail.reason;
				}
			});
		}
	}
}

document.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('.block-post-feed').forEach((block) => {
		new PostFeed(block);
	});
});
